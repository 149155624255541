.Main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.SignInContainer {
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 400px;
  background-color: pink;
  margin: 25px;
}

.SignInButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 20px;
}

.SignInButton>input {
  width: 60%;
  height: 100%;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid black;
  background-color: white;
}

.SignInButton>input:hover {
  background-color: lightgray;
  cursor: pointer;
}

.Username, .Password {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 10px 0;
    padding: 0 10px;
    font-size: 20px;
}

.Title, .SignInButton, .MakeAdminButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0 10px;
  font-size: 20px;
}

.ErrorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 20px;
  margin: 10px 0;
}

.AddBeerContainer {
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 400px;
  background-color: pink;
  margin: 25px;

  .Title {
    font-size: 20px;
  }
}

.AddBeerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.AddBeerForm > label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  margin: 5px 0;
  padding: 0 10px;
  font-size: 20px;
}