.sheetsMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.apiKey {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.getCategories {
    margin: 5px;
    border-radius: 100%;
    cursor: pointer;
}

.sheetsLabel {
    margin: 10px;
    font-size: 16px;
    color: #000;
}

.sheetsInput {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    border: black 1px solid;
}

.sheetsButton {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    color: #000;
    background-color: #E5E4E2;
    font-size: 16px;
    border: none;
}

.sheetsButton:hover {
    background-color: #C0C0C0;
}