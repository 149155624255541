.AdminTasks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    flex-wrap: wrap;

    > button {
        margin: 10px;
        height: 70px;
        font-size: 20px;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}

.LogoutButton > button {
    width: 150px;
    height: 60px;
    font-size: 20px;
    border-radius: 10px;
    background-color: cornflowerblue;

    &:hover {
        cursor: pointer;
    }
}

.AdminButton {
    width: 150px;
    height: 60px;
    font-size: 20px;
    border-radius: 10px;
    background-color: #E5E4E2;

    &:hover {
        cursor: pointer;
        background-color: #C0C0C0;
    }
}